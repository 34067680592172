import React, { 
    useEffect, 
    useState 
} from 'react';
import { 
    Container, 
    Card, 
    Form, 
    Col, 
    Row, 
    Alert, 
    Button, 
    Image, 
    Figure 
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import MaskedInput from 'react-maskedinput';

import InputData from './../../../Painel/Componentes/InputData';
import rocaPng from './../../../../assets/rocaSeguroAventura.png';

// cadastrar cliente único ao evento
export default function CadastroClienteSeguroAventuraPropriedadeDiarias() {

    // dados
    const { token, tokenDiarias } = useParams();
    const [carregando, alterarCarregando] = useState(true);
    const [dadosCliente, alterarDadosCliente] = useState({});
    const [dadosParticipante, alterarDadosParticipante] = useState({
        numeroInscricao: "1",
        estrangeiro: "N",
        numeroPassaporte: null,
        dataCadastro: null,
        cpf: null,
        dataNascimento: null,
        restricoesDadosSaude: null,
        nacionalidade: null,
        contatoEmergenciaTelefone: null,
        contatoEmergenciaNome: null,
        email: null,
        telefone: null,
        nome: null,
        id: null
    });
    const [jwt, alterarJwt] = useState(null);
    const [erros, alterarErros] = useState({});
    const [erro, alterarErro] = useState(null);
    const [salvando, alterarSalvando] = useState(false);
    const [finalizado, alterarFinalizado] = useState(false);
    const [nomeImagem, alterarNomeImagem] = useState(false);
    const [consultandoCpf, alterarConsultandoCpf] = useState(false);
    const [dataFinal, alterarDataFinal] = useState(null);
    const [numeroDiarias, alterarNumeroDiarias] = useState(0);
    const [aceitouTermos, alterarAceitouTermos] = useState(false);
    const [eventoBloqueado, alterarEventoBloqueado] = useState(false);
    const [mostrarDadosSaude, alterarMostrarDadosSaude] = useState(false);
    const [aceitouTermosCheckbox, alterarAceitouTermosCheckbox] = useState(false);
    const [mostrarContatoEmergencia, alterarMostrarContatoEmergencia] = useState(false);

    // referencias
    const telefoneInput = React.createRef();
    const telefoneEmergenciaInput = React.createRef();
    const cpfInput = React.createRef();

    // função responsável por identificar o número de diárias do evento
    function identificarNumeroDiarias(diariasEventoCheckin) {
        
        // tranforma o json em objeto
        const diariasArray = JSON.parse(diariasEventoCheckin);

        // percorre array e encontra o número de diárias do evento atual
        const diariaEventoAtual = diariasArray
        .find(diaria => diaria.numeroDiariasToken === tokenDiarias);

        // resgata data atual
        const dataAtual = new Date();

        // adiciona o número de diárias
        const dataAtualFormatada = moment(dataAtual).add(diariaEventoAtual.numeroDiarias - 1, 'days');

        // altera o número de diárias do evento
        alterarNumeroDiarias(diariaEventoAtual.numeroDiarias - 1);
        
        // se a data é válida
        if(moment(dataAtualFormatada).isValid()) {
            
            // formata a data com a data com adicão
            alterarDataFinal(dataAtualFormatada.format('DD/MM/YYYY'));

        } else {

            // formata data atual
            alterarDataFinal(dataAtual.format('DD/MM/YYYY'));

        }       

    }

    // consultar token
    async function consultarToken() {

        // mostra carregamento
        alterarCarregando(true);

        try {

            // resgata dados do token
            const { data } = await axios.get(`/seguroAventura/validarTokenCliente/${token}`);

            // altera estados
            alterarJwt(data.jwt);
            alterarDadosCliente(data.dadosCliente);
            alterarDadosParticipante({
                ...dadosParticipante,
                numeroInscricao: (parseInt(data.totalParticipantes) + 1).toString().padStart(3, '0')
            });
            identificarNumeroDiarias(data.dadosCliente.diariasEventoCheckin);

            // se está bloqueado
            if(data.dadosCliente.seguroPropriedadeBloqueado === "S") {

                // finaliza
                alterarFinalizado(true);
                alterarEventoBloqueado(true);
                
            }

        } catch ({ response }) {

            // verifica response
            if (response && response.status && response.status === 400) {

                // se houve retorno de erro
                if (response.data) {

                    // altera
                    alterarErro(Object.values(response.data));

                } else {

                    // altera
                    alterarErro("Não foi possível carregar o evento!");

                }
            }
        }
        finally {

            // esconde carregamento
            alterarCarregando(false);

        }
    }

    // salvar participante 
    async function salvar() {

        // insere carregamento
        alterarSalvando(true);

        try {

            // faz a requisição pra incluir o participante no evento propriedade
            const { data } = await axios.post(
                `/seguroAventuraParticipante/evento/${dadosCliente.id}`, 
                {
                    dados: {
                        participante: {
                            ...dadosParticipante,
                            aceitouTermos: aceitouTermos ? "S" : "N"
                        },
                        cliente: dadosCliente,
                        dataFinal: moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        tokenDiarias
                    }
                },
                {
                    headers: {
                        Authorization: jwt
                    }
                }
            );

            // altera imagem
            alterarNomeImagem(data.dadosImagemModeloEmailParticipante.nomeTemporario);

            // finaliza
            alterarFinalizado(true);

        } catch ({ response }) {

            // verifica response
            if (response && response.status && response.status === 400) {

                // se veio retorno de erro
                if (response.data) {

                    // verifica se é erro de participante 
                    if (Object.keys(response.data).includes('participante')) {

                        // altera erros de participante
                        alterarErros(response.data.participante);

                    } else {

                        // altera erro do evento
                        alterarErro(Object.values(response.data));

                    }

                }

            }

        } finally {
            alterarSalvando(false);
        }

    }

    // consulta cpf de participante já cadastrado
    async function consultarCpf() {

        // ativa carregamento da consulta do cpf
        alterarConsultandoCpf(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventuraParticipante/cpf`, {
                params: {
                    cpf: dadosParticipante.cpf
                },
                headers: {
                    Authorization: jwt
                }
            });

            // resgata dados cpf e preenche campos
            const dadosCpf = Object.assign(dadosParticipante, {
                nome: data.dados.nome,
                dataNascimento: data.dados.dataNascimento,
                email: data.dados.email,
                telefone: data.dados.telefone,
                contatoEmergenciaTelefone: data.dados.contatoEmergenciaTelefone,
                contatoEmergenciaNome: data.dados.contatoEmergenciaNome,
                restricoesDadosSaude: data.dados.restricoesDadosSaude
            });

            // verifica para ativar checkbox
            alterarMostrarContatoEmergencia((dadosCpf.contatoEmergenciaNome || dadosCpf.contatoEmergenciaTelefone) ? true : false);
            alterarMostrarDadosSaude(dadosCpf?.restricoesDadosSaude ? true : false);

            // altera 
            alterarDadosParticipante(dadosCpf);

        } catch ({ response }) { 
            console.error(response)
        } finally {
            alterarConsultandoCpf(false);
        }

    }

    // inicializa
    useEffect(() => {
        consultarToken();
    }, []);

    // sempre que o cpf for alterado
    useEffect(() => {

        // se preenchido faz a consulta do cpf
        if (dadosParticipante.cpf !== null && dadosParticipante.cpf.length === 11) {
            consultarCpf();
        }

    }, [dadosParticipante.cpf]);
    
    // carregando os dados do token
    if (carregando) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <Container>
                <div className="text-center text-white pt-5">
                    Aguarde <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                </div>
            </Container>
        </div>
    }

    // se encontrado erro
    if (erro) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <Container>
                <div className="text-center text-white pt-5">
                    {erro}
                </div>
            </Container>
        </div>
    }

    // se finalizado
    if (finalizado) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <Container>
                <div className="text-center text-white pt-5">
                    Seus dados foram cadastrados com sucesso!
                </div>
                <div className="text-center text-white pt-2">
                    {dadosCliente.seguroEventoDescricao}
                </div>
                <div className="text-center text-white pt-2">
                    <Button
                        variant="padrao-azul"
                        className="botao-cadastrar shadow-none"
                        onClick={() => window.location.reload()}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                        <span>Novo participante</span>
                    </Button>
                </div>
                {nomeImagem &&
                    <Form.Group style={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        <Figure style={{
                            maxWidth: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            marginBottom: 0
                        }}
                        >
                            <Image
                                src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/imagensEmail/${nomeImagem}`}
                                fluid
                                rounded
                                style={{
                                    maxHeight: '75vh',
                                    minHeight: 300,
                                    flexGrow: 1,
                                    marginTop: 10
                                }}
                                alt='Imagem modelo email do seguro aventura'
                                responsive
                            />
                        </Figure>
                    </Form.Group>
                }
            </Container>
        </div>
    } 

    // se tem de mostrar termos
    if(dadosCliente.exibirTermos === "S" && !aceitouTermos) {
        return <div className="vh-100 vw-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <div
                className='d-flex w-100 h-100 align-items-center justify-content-center flex-column'
            >
                <iframe 
                    id='iframe-pdf'
                    src={`${axios.defaults.baseURL}/clientes/baixarArquivoTermo?linkTermoResponsabilidade=${dadosCliente.linkTermoResponsabilidade}&id=${dadosCliente.id}`}
                    style={{
                        flex: 1,
                        minWidth: '50vw',
                        maxWidth: '80vw',
                        maxHeight: '75vh',                        
                        border: '1px solid black'                        
                    }}
                />
                <div 
                    className='d-flex align-items-center justify-content-center w-100' 
                    style={{
                        height: 100, 
                        flexDirection: window.innerWidth < 500 ? 'column' : 'row'
                    }}
                >
                    <Form.Check
                        inline
                        label="ESTOU CIENTE E ACEITO OS TERMOS"
                        id={`checkbox-participante-termos`}
                        type='checkbox'                    
                        color='white'                    
                        onChange={e => alterarAceitouTermosCheckbox(!aceitouTermosCheckbox)}
                        checked={aceitouTermosCheckbox}
                        style={{ color: 'white' }}
                    />
                    {(aceitouTermosCheckbox) && <Button
                        className={["botao-cadastrar shadow-none",  window.innerWidth < 500 ? "mt-1" : "ml-1"].join(' ')}                         
                        onClick={() => alterarAceitouTermos(true)}
                    > 
                        <span>Avançar</span>                        
                    </Button>}
                </div>
            </div>
        </div>
    }
    
    // componente
    return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
        <style>
            {'body {background-color: #595959;}'}
        </style>
        <Container>
            <Row>
                <Col 
                    lg={{ 
                        span: 6, 
                        offset: 3 
                    }} 
                    md={{ 
                        span: 8, 
                        offset: 2 
                    }} 
                    sm={{ 
                        span: 10, 
                        offset: 1 
                    }}
                >
                    <Card className="mt-4 mb-4">
                        <Card.Body>
                            <Form.Group
                                className='d-flex text-center align-items-center justify-content-center flex-1'
                            >
                                <Figure
                                    className='mw-100 align-items-center justify-content-center flex-grow-1 mb-0'
                                >
                                    <Image
                                        src={rocaPng}
                                        fluid
                                        rounded
                                        style={{
                                            maxHeight: 150,
                                            flexGrow: 1
                                        }}
                                    />
                                </Figure>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control
                                    value={dadosCliente.nome || ''}
                                    maxLength='100'
                                    size="sm"
                                    disabled={true}
                                    isInvalid={erros.empresa}
                                />
                                {erros.empresa &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.empresa}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Local</Form.Label>
                                <Form.Control
                                    value={dadosCliente.seguroEventoLocal || ''}
                                    maxLength='100'
                                    disabled={true}
                                    size="sm"
                                />
                            </Form.Group>

                            <Form.Group 
                                className='justify-content-between flex-1 d-flex'
                            >
                                <Form.Group style={{ width: 150 }}>
                                    <Form.Label>Início em*</Form.Label>
                                    <Form.Group 
                                        className='mb-0 flex-1 justify-content-center align-items-center d-flex direction-row'
                                    >
                                        <Form.Control
                                            value={moment(new Date()).format('DD/MM/YYYY')}
                                            maxLength='20'
                                            disabled={true}
                                            size="sm"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </Form.Group>
                                    {erros.dataEntrada &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.dataEntrada}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group style={{ width: 150 }}>
                                    <Form.Label>Final em*</Form.Label>
                                    <Form.Group 
                                        className='mb-0 flex-1 justify-content-center align-items-center d-flex direction-row'
                                    >
                                        <Form.Control
                                            value={dataFinal ?? ''}
                                            maxLength='20'
                                            disabled={true}
                                            size="sm"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </Form.Group>
                                    {erros.dataSaida &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.dataSaida}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Form.Group>

                            <hr />

                            <Form.Group>
                                <Form.Label className="d-flex align-items-center mt-1">
                                    <span className=" flex-grow-1">
                                        {dadosParticipante.estrangeiro === "N" ? "CPF*" : "Número Passaporte*"}

                                    </span>
                                    <Form.Check
                                        label="É estrangeiro?"
                                        id="checkbox-participante-estrangeiro"
                                        onChange={e => {
                                            dadosParticipante.estrangeiro = e.target.checked ? "S" : "N";
                                            alterarDadosParticipante({ ...dadosParticipante });
                                        }}
                                        checked={dadosParticipante.estrangeiro === "S"}
                                    />
                                </Form.Label>

                                {dadosParticipante.estrangeiro === "N" &&
                                    <>
                                        <MaskedInput
                                            className={[
                                                "form-control form-control-sm",
                                                (erros.cpf) && 'is-invalid'
                                            ].join(' ')}
                                            mask="111.111.111-11"
                                            value={dadosParticipante.cpf}
                                            ref={cpfInput}
                                            onChange={(e) => {
                                                dadosParticipante.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                                alterarDadosParticipante({ ...dadosParticipante });
                                            }}
                                            style={consultandoCpf ? { width: '95%', display: 'inline-block' } : {}}
                                        />
                                        {(erros.cpf) &&
                                            <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                                        }

                                        <span className=" flex-grow-1">
                                            {consultandoCpf &&
                                                <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                                            }
                                        </span>

                                    </>
                                }

                                {dadosParticipante.estrangeiro === "S" &&
                                    <>
                                        <Form.Control
                                            value={dadosParticipante.numeroPassaporte || ''}
                                            maxLength='50'
                                            onChange={e => {
                                                alterarDadosParticipante({ ...dadosParticipante, numeroPassaporte: e.target.value })
                                            }}
                                            size="sm"
                                            isInvalid={erros.numeroPassaporte}
                                        />
                                        {erros.numeroPassaporte &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.numeroPassaporte}
                                            </Form.Control.Feedback>
                                        }
                                    </>
                                }

                            </Form.Group>

                            <Form.Group>
                                <Form.Group>
                                    <Form.Label>Nome Completo*</Form.Label>
                                    <Form.Control
                                        value={dadosParticipante.nome || ''}
                                        maxLength='100'
                                        onChange={e => {
                                            alterarDadosParticipante({ 
                                                ...dadosParticipante, nome: e.target.value 
                                            })
                                        }}
                                        size="sm"
                                        isInvalid={erros.nome}
                                    />
                                    {erros.nome &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.nome}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Form.Group>

                            {dadosParticipante.estrangeiro === "S" &&
                                <Form.Group>
                                    <Form.Label>Nacionalidade</Form.Label>
                                    <Form.Control
                                        value={dadosParticipante.nacionalidade || ''}
                                        maxLength='50'
                                        onChange={e => {
                                            alterarDadosParticipante({ ...dadosParticipante, nacionalidade: e.target.value })
                                        }}
                                        size="sm"
                                        isInvalid={erros.nacionalidade}
                                    />
                                    {erros.nacionalidade &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.nacionalidade}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Label>Nascimento*</Form.Label>
                                <InputData
                                    data={dadosParticipante.dataNascimento}
                                    onChange={(data) => {
                                        dadosParticipante.dataNascimento = data
                                        alterarDadosParticipante({ ...dadosParticipante });
                                    }}
                                    size="sm"
                                    isInvalid={erros.dataNascimento}
                                />
                                {erros.dataNascimento &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.dataNascimento}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <>
                                {(erros.dataNascimento || (dadosParticipante.dataNascimento && dadosParticipante.dataNascimento.length === 10 && moment().diff(dadosParticipante.dataNascimento, 'years') <= 14)) &&
                                    <Alert variant="danger" className="aviso-data p-1 border-0">
                                        Para os menores de 14 anos a cobertura de Morte Acidental estará limitada ao reembolso de despesas com o funeral.
                                    </Alert>
                                }
                            </>

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={dadosParticipante.email || ''}
                                    maxLength='100'
                                    isInvalid={erros.email}
                                    onChange={e => {
                                        alterarDadosParticipante({ 
                                            ...dadosParticipante, 
                                            email: e.target.value === '' ? null : e.target.value 
                                        });
                                    }}
                                    size="sm"
                                />
                                {erros.email &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.email}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Telefone</Form.Label>
                                <MaskedInput
                                    className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                    mask="(11) 11111-1111"
                                    value={dadosParticipante.telefone || ''}
                                    ref={telefoneInput}
                                    onChange={(e) => {
                                        dadosParticipante.telefone = telefoneInput.current.mask.getRawValue().split('_').join('');
                                        if(dadosParticipante.telefone === '') dadosParticipante.telefone = null;
                                        alterarDadosParticipante({ ...dadosParticipante });
                                    }}
                                    onPasteCapture={async (e) => {

                                        e.preventDefault();

                                        // resgata texto que está colado
                                        let textoColado = await navigator.clipboard.readText();

                                        if(typeof textoColado !== "string") return;

                                        // remove caracteres especiais e deixa somente numeros
                                        textoColado = textoColado.replace(/\s+/g, '').replace(/\D/g, '');

                                        // monta mascara
                                        const mascara = '11111111111';
                                        
                                        // prepara valor formatado
                                        let novoValor = '';
                                        let j = 0;

                                        // percorre se baseando pela mascara
                                        for (let i = 0; i < mascara.length; i++) {

                                            // resgata valor atual
                                            const valor = textoColado[j++];
                                            
                                            // verifica se é numero e insere a variavel
                                            if(!isNaN(valor)) novoValor += valor;
                                            
                                        }

                                        // altera dados do telefone
                                        dadosParticipante.telefone = novoValor.split('_').join('').replace(/\s+/g, '');
                                        alterarDadosParticipante({ ...dadosParticipante });
                                    }}
                                />
                                {erros.telefone &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.telefone}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className={'d-flex justify-content-end'}>
                                <Form.Check
                                    inline
                                    label="Informar contato emergência?"
                                    id={`checkbox-participante-contato-emergencia`}
                                    type='checkbox'                  
                                    onChange={(e) => {
                                    
                                        // resgata novo valor
                                        const mostrar = !mostrarContatoEmergencia;
    
                                        // altera valor do check box
                                        alterarMostrarContatoEmergencia(mostrar);
    
                                        // se está escondendo
                                        if(!mostrar) {
    
                                            // remove dados de emergencia
                                            alterarDadosParticipante({
                                                ...dadosParticipante,
                                                contatoEmergenciaTelefone: null,
                                                contatoEmergenciaNome: null
                                            });
    
                                        }
                                        
                                    }}
                                    checked={mostrarContatoEmergencia}
                                    style={{ color: 'black' }}
                                />
                            </Form.Group>

                            <Form.Group className={'d-flex justify-content-end'}>
                                <Form.Check
                                    inline
                                    label="Informar restrições e dados de saúde?"
                                    id={`checkbox-participante-dados-saude`}
                                    type='checkbox'                  
                                    onChange={(e) => {
                                    
                                        // resgata novo valor
                                        const mostrar = !mostrarDadosSaude;
    
                                        // altera valor do check box
                                        alterarMostrarDadosSaude(mostrar);
    
                                        // se está escondendo
                                        if(!mostrar) {
    
                                            // remove dados saude
                                            alterarDadosParticipante({
                                                ...dadosParticipante,
                                                restricoesDadosSaude: null
                                            });
    
                                        }
                                        
                                    }}
                                    checked={mostrarDadosSaude}
                                    style={{ color: 'black' }}
                                />
                            </Form.Group>

                            {mostrarContatoEmergencia ? <> 
                                <Form.Group>
                                    <Form.Label>
                                        Nome
                                    </Form.Label>
                                    <Form.Control
                                        value={dadosParticipante.contatoEmergenciaNome || ''}
                                        maxLength='100'
                                        onChange={e => {
                                            alterarDadosParticipante({ 
                                                ...dadosParticipante, 
                                                contatoEmergenciaNome: e.target.value 
                                            })
                                        }}
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Telefone
                                    </Form.Label>
                                    <MaskedInput
                                        className={"form-control form-control-sm"}
                                        mask="(11) 11111-1111"
                                        value={dadosParticipante.contatoEmergenciaTelefone || ''}
                                        ref={telefoneEmergenciaInput}
                                        onChange={(e) => {
                                            dadosParticipante.contatoEmergenciaTelefone = telefoneEmergenciaInput.current.mask.getRawValue().split('_').join('')
                                            alterarDadosParticipante({ ...dadosParticipante });
                                        }}
                                        onPasteCapture={async (e) => {

                                            e.preventDefault();
    
                                            // resgata texto que está colado
                                            let textoColado = await navigator.clipboard.readText();
    
                                            if(typeof textoColado !== "string") return;
    
                                            // remove caracteres especiais e deixa somente numeros
                                            textoColado = textoColado.replace(/\s+/g, '').replace(/\D/g, '');
    
                                            // monta mascara
                                            const mascara = '11111111111';
                                            
                                            // prepara valor formatado
                                            let novoValor = '';
                                            let j = 0;
    
                                            // percorre se baseando pela mascara
                                            for (let i = 0; i < mascara.length; i++) {
    
                                                // resgata valor atual
                                                const valor = textoColado[j++];
                                                
                                                // verifica se é numero e insere a variavel
                                                if(!isNaN(valor)) novoValor += valor;
                                                
                                            }
    
                                            // altera dados do telefone
                                            dadosParticipante.contatoEmergenciaTelefone = novoValor.split('_').join('').replace(/\s+/g, '');
                                            alterarDadosParticipante({ ...dadosParticipante });
                                        }}
                                    />
                                </Form.Group>
                            </> : <></>}

                            {mostrarDadosSaude ? <Form.Group>
                                <Form.Label>Restrições e dados de saúde</Form.Label>
                                <Form.Control
                                    value={dadosParticipante.restricoesDadosSaude || ''}
                                    onChange={e => {
                                        alterarDadosParticipante({ 
                                            ...dadosParticipante, 
                                            restricoesDadosSaude: e.target.value 
                                        })
                                    }}
                                    size="sm"
                                    as="textarea"
                                    rows={3}
                                />
                            </Form.Group> : <></>}

                            <p
                                style={{
                                    fontWeight: 'bold',
                                    fontStyle: 'italic',
                                    fontSize: 10
                                }}
                            >
                                Autorizo o uso dos meus dados pessoais para emissão do Seguro Aventura, e declaro que estou ciente e fui informado pelo promotor do evento dos riscos envolvidos na prática das atividades de turismo e esportes de aventura. Autorizo também, a veiculação de imagens colhidas neste evento para fins promocionais, e para inserção nas suas redes sociais.
                            </p>

                            <div className="text-right">
                                <Button
                                    variant="primary"
                                    onClick={salvar}
                                    disabled={salvando}
                                >
                                    <span>Salvar</span>
                                    {salvando &&
                                        <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                                    }
                                </Button>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>


}