import React, { useState, useEffect, useContext, useRef  } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import Geocode from 'react-geocode';

// componentes
import { Button, Card } from 'react-bootstrap';

// contexto
import { ClientesMapaContexto } from './../../ClientesMapa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// função
export default function ConsultaProximidade({
    chaveApi
}){

    let mapaDados = useContext(ClientesMapaContexto);
    const [carregado, alterarCarregado] = useState(false); 
    const [consultando, alterarConsultando] = useState(false);   
    const [listaMinimizada, alterarListaMinizada] = useState(false);
    const [pagina, alterarPagina] = useState(1);
    
    useEffect(() => {

        if(!carregado && mapaDados.clienteSelecionado !== null){
            consultarClientes();
        }

        if(carregado && mapaDados.clienteSelecionado !== null && mapaDados.clienteSelecionado.latitude === null){
            alterarCarregado(false);
            alterarPagina(1);
        }

    }, [mapaDados.clienteSelecionado])

    useEffect(() => {
        if(mapaDados.cliente === null){
            mapaDados.alterarClientesProximidade([]);
            alterarCarregado(false);
            alterarPagina(1);
        }
    }, [mapaDados.cliente]);

    useEffect(() => {
        
        alterarPagina(1);

        // verifica se foi selecionado grupo ou estado
        if(
            (
                mapaDados.filtros.grupos && 
                mapaDados.filtros.grupos.length > 0
            ) || (
                mapaDados.filtros.estado &&
                mapaDados.filtros.estado !== ''
            )
        ) {
            mapaDados.alterarClientesProximidade([]);                        
            consultarClientes();
        } else if(!mapaDados.clienteSelecionado) {
            mapaDados.alterarClientesProximidade([]);
        }        

    }, [mapaDados.filtros]);

    // ao alterar página
    useEffect(() => {
        // somente consulta novamente caso tenha filtro de grupos, estado ou cliente selecionado
        if(
            (
                mapaDados.filtros && (
                    mapaDados.filtros.grupos.length > 0 || (
                        mapaDados.filtros.estado && 
                        mapaDados.filtros.estado.length > 1 
                    )
                )
            ) || 
            mapaDados.clienteSelecionado
        ) { 
            consultarClientes();
        }
    }, [pagina]);

    // buscar cordenadas do endereço
    async function buscarCoordenadas(dados){

        // prepara retorno das coordenadas
        let coordenadas = {
            latitude: null,
            longitude: null
        }

        try {

            // resgata resposta da localização a partir do endereço
            const response = await Geocode.fromAddress(`${dados.endereco} ${dados.numero} - ${dados.bairro}, ${dados.cidade} - ${dados.estado} ${dados.cep} `, chaveApi);
                    
            // resgata coordenadas
            const { 
                lat, 
                lng 
            } = response.results[0].geometry.location;
            
            // altera
            coordenadas = {
                latitude: lat,
                longitude: lng
            }            

        } catch(e) {
            console.error(e);
        } finally {

            // retorna as coordenadas encontradas ou não
            return coordenadas;

        }
        
    }

    // salvar endereço com coordenadas
    async function salvarEndereco(dados) {

        try {

            // salvarSimples
            await axios.post(`/clientes/salvarSimples`, {
                dados: {
                    id: dados.id,
                    latitude: dados.latitude,
                    longitude: dados.longitude,
                    endereco: dados.endereco,
                    bairro: dados.bairro,
                    numero: dados.numero,
                    cidade: dados.cidade,
                    estado: dados.estado,
                    cep: dados.cep
                }
            });

        }catch({response}){}

    }

    // consultar clientes próximos
    async function consultarClientes() {

        // ativa o carregamento
        alterarConsultando(true);

        // adiciona parametros
        const parametros = {
            pagina,
            registrosPorPagina: 15                       
        }

        // adiciona coordenadas aos parametros
        if(mapaDados.clienteSelecionado) {
            parametros['latitude'] = mapaDados.clienteSelecionado.latitude;
            parametros['longitude'] = mapaDados.clienteSelecionado.longitude;    
            parametros['coordenadaObrigatoria'] = "S";
        }
        
        try {

            // faz a consulta com os filtros
            const { data } = await axios.get(`/clientes`, {
                params: Object.assign(parametros, mapaDados.filtros)
            });

            // se não está com o cliente selecionado
            if(!mapaDados.clienteSelecionado) {

                // faz a promise para terminar a iteração
                const consultarCoordenadas = data.registros.map(async (registro, index) => {

                    // resgata registro
                    let registroRetorno = registro;
                    
                    // verifica se não possui coordenadas
                    if(!registroRetorno.latitude || !registroRetorno.longitude) {
                        
                        // busca coordenadas via Geocode
                        const coordenadas = await buscarCoordenadas(registro);

                        // adiciona coordenadas ao cliente
                        data.registros[index].latitude = coordenadas.latitude;
                        data.registros[index].longitude = coordenadas.longitude;    

                        // salva no banco com suas coordenadas
                        await salvarEndereco(data.registros[index]);                    

                    }

                    // retorna registro
                    return registroRetorno;

                });

                // espera a promise finalizar
                await Promise.all(consultarCoordenadas);

            }

            // incrementa ou inicia
            if(!carregado || pagina === null || pagina === 1){
                mapaDados.alterarClientesProximidade(data.registros);
            }else{
                mapaDados.alterarClientesProximidade([...mapaDados.clientesProximidade, ...data.registros]);
            }

            // finaliza
            alterarCarregado(true);

        }catch({response}){
            console.error(response);
        }
        finally {
            alterarConsultando(false);
        }
        
    }    
    
    // se não tiver dados
    if((mapaDados.clientesProximidade.length === 0 || !carregado) && !consultando) return <></>
    
    return <div className={styles.cardProximidade}>
        <Card>
            <Card.Header className={styles.cardCabecalho}>
                <div className='d-flex direction-row justify-content-center align-items-center'>
                    <h5 className={styles.tituloLista}>
                        Lista de Clientes:
                    </h5>
                    <h5 className={styles.tituloLista}>
                        {
                            (
                                mapaDados.clientesProximidade && 
                                mapaDados.clientesProximidade.length
                            ) ? mapaDados.clientesProximidade.length : 0
                        }
                    </h5>                
                </div>
                <FontAwesomeIcon 
                    className={styles.btMinimizar}
                    icon={['fas', 'minus']}
                    onClick={() => alterarListaMinizada(!listaMinimizada)}
                />
            </Card.Header>
            <Card.Body 
                className={styles.cardCorpo}
                style={{ display: listaMinimizada ? 'none' : 'block' }}
            >
                <div className={[styles.registros, 'p-1'].join(' ')}>

                    {(!consultando) ? mapaDados.clientesProximidade.map((cliente) => 
                        <Card 
                            key={cliente.id}
                            className={[
                                styles.registro, 
                                'mb-1 p-1 border-0 rounded',
                                (mapaDados.clienteSelecionado && mapaDados.clienteSelecionado.id === cliente.id) ? styles.selecionado : '',
                                (!cliente.latitude || !cliente.longitude) ? ' bg-danger' : ' bg-light'
                            ].join(' ')}
                            onClick={() => {
                                mapaDados.alterarCliente(cliente.id);
                            }}
                        >
                            <Card.Body className={'p-1'}>
                                <div>
                                    {cliente.nome}
                                </div>
                                {(cliente.distancia) && <div className='text-right'>
                                    <small>
                                        {   (parseFloat(cliente.distancia) < 1) ?
                                            <>{(parseFloat(cliente.distancia) * 1000).toFixed()} m</>
                                            :
                                            <>{parseFloat(cliente.distancia).toFixed(1)} km</>

                                        }
                                    </small>
                                </div>}
                            </Card.Body>
                        </Card>
                    ) : <FontAwesomeIcon 
                        className="icone fa-lg w-100" 
                        pulse 
                        icon={["fas", 'spinner']} 
                    />}
                    
                </div>
            </Card.Body>        
            {
                (
                    pagina !== null && 
                    !listaMinimizada && 
                    carregado
                ) && <Card.Footer className={styles.cardRodape}>
                    <Button
                        variant='padrao'
                        onClick={() => {
                            alterarPagina(pagina + 1);
                        }}
                    >
                        Carregar mais
                    </Button>
                    <Button
                        variant='padrao'
                        onClick={() => {
                            alterarPagina(null);
                        }}
                        className='ml-2'
                    >
                        Todos
                    </Button>
                </Card.Footer>
            }
        </Card>
    </div>

}
