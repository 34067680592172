import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString  from 'query-string';

// componentes
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carregador from './../../Componentes/Carregador';
import PaginacaoComponente from './../../Componentes/Paginacao';
import Parametros from './Parametros';
import Registro from './Registro';
import GerarRelatorioSeguradoras from './Parametros/GerarRelatorioSeguradoras';
import GerarRelatorioConsulta from './GerarRelatorioConsulta';

// consulta dos clientes
export default function ClientesPagina(){

    // parametros de consulta
    const history = useHistory();
    const location = useLocation();
    const { path } = useRouteMatch();

    // variaveis
    const [parametros, alterarParametros] = useState(Object.assign({
        pagina: 1,
        data_cadastro: '',
        estado: null,
        id_plano: null,
        data_boleto_vencimento: '',
        data_boleto_a_vencer: '',
        clientes_em_atraso: 'N',
        cliente_documento_autentique_assinado: [],
        acima_data_cadastro: 'N',
        fatura_minima_anterior: 'N',
        registrosPorPagina: 10,
        ativo: ['S'],
        tipo_pessoa: ['F', 'J'],
        texto: '',
        textoGeral: 'S',
        pre_cliente: ['S', 'N'],
        responsaveis: [],
        grupos: [],
        grupos_desconsiderados: [],
        sem_emissao_ultimos_meses: 'N',
        tipo_cliente: [],
        campo_vazio: '',
        possui_vigencia: 'N',
        sexo: ['M', 'F'],
        placa_veiculo: '',
        numero_apolice: ''
    }, queryString.parse(location.search, {arrayFormat: 'bracket'})));
    const [totalRegistros, atualizarTotalRegistros] = useState(0);
    const [carregando, mostrarCarregando] = useState(false);

    // variavel para armazenar dados da consulta
    const [consulta, alterarConsulta] = useState([]);

    // consulta clientes com filtros
    async function consultar() {

        // ativa carregamento
        mostrarCarregando(true);

        // codifica a query para salvar no navegador
        let encodeQuery = queryString.stringify(parametros, {arrayFormat: 'bracket'});
        
        // atualizar url
        history.replace({
            pathname: path,
            search: "?"+encodeQuery
        });

        try {

            // realiza a requisição
            const { data } = await axios.get('/clientes', {
                params: parametros
            })

            // atualiza registros
            alterarConsulta(data.registros);

            // atualiza total de registros
            atualizarTotalRegistros(parseInt(data.totalRegistros));

        } catch(e) {
            console.error(e);
        } finally {
            
            // desativa carregamento
            mostrarCarregando(false);

        }

    }

    // inicia a aplicação
    useEffect(() => {        
        consultar()
    }, [parametros]);

    let verDados = (dados) => {
        // console.log(dados);
    }

    // retorna componente
    return <Row>
        <Col>
            <Card className="painel-consulta border-0 shadow-sm" >
                <div className="card-header ">
                    <div className='d-flex'>
                        <h4 className="card-title font-weight-bolder flex-grow-1">Clientes</h4>
                        <div>

                            <GerarRelatorioConsulta 
                                parametros={parametros}
                            />

                            <GerarRelatorioSeguradoras />

                            <OverlayTrigger 
                                overlay={<Tooltip>Envia sms para todos os clientes presentes na consulta atual!</Tooltip>}
                                placement='bottom'
                            >
                                <Button
                                    className='mr-2 rounded-circle'
                                    variant='padrao'
                                    size='sm'
                                    onClick={() => {
                                        history.push('/painel/clientes/enviar-sms-massa', {
                                            parametros
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon style={{minWidth: '13px'}} className="icone" icon={["fas", "mobile-alt"]} />
                                    {/* <span>Mensagens em massa</span> */}
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                overlay={<Tooltip>Envia whatsapp para todos os clientes presentes na consulta atual!</Tooltip>}
                                placement='bottom'
                            >
                                <Button
                                    className='mr-2 rounded-circle'
                                    variant='padrao'
                                    size='sm'
                                    onClick={() => {
                                        history.push('/painel/clientes/enviar-whatsapp-massa', {
                                            parametros
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon 
                                        style={{ minWidth: '13px' }} 
                                        className="icone" 
                                        icon={["fab", "whatsapp"]} 
                                    />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                overlay={<Tooltip>Envia email para todos os clientes presentes na consulta atual!</Tooltip>}
                                placement='bottom'
                            >
                                <Button
                                    className='rounded-circle'
                                    variant='padrao'
                                    size='sm'
                                    onClick={() => {
                                        history.push('/painel/clientes/emails-massa', {
                                            parametros
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon className="icone" icon={["fas", "mail-bulk"]} />
                                    {/* <span>Emails em massa</span> */}
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    {/* <p className="card-category">Here is a subtitle for this table</p> */}

                    <Parametros parametros={parametros} alterarParametros={alterarParametros} />
                </div>
                
                <Card.Body>
                    
                    <table className="table table-hover tabela">
                        <thead>
                            <tr>
                                <th>Código</th>
                                { (parametros.tipo_pessoa.includes('J') && parametros.tipo_pessoa.includes('F'))
                                && <th>F/J</th>}
                                <th>Nome</th>
                                { (!parametros.tipo_pessoa.includes('J') && parametros.tipo_pessoa.includes('F'))
                                && <th>Nasc.</th>}
                                { (parametros.tipo_pessoa.includes('J') && !parametros.tipo_pessoa.includes('F'))
                                && <th>Nome Fantasia</th>}
                                <th>UF</th>
                                <th>Cidade</th>

                                { (parametros.tipo_pessoa.includes('J') && !parametros.tipo_pessoa.includes('F'))
                                && <th>CNPJ</th>}
                                { (!parametros.tipo_pessoa.includes('J') && parametros.tipo_pessoa.includes('F'))
                                && <th>CPF</th>}
                                { (parametros.tipo_pessoa.includes('J', 'F'))
                                && <th>CPF/CNPJ</th>}

                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consulta && consulta.map((registro, a) => 
                                <Registro 
                                    opcaoAleatorio={() => verDados(registro)} 
                                    key={a} 
                                    dados={registro}
                                    parametros={parametros}
                                    onClickTr={() => 
                                        history.push(`/painel/clientes/alterar/${registro.id}`)
                                    }
                                    clicouEditar={() => 
                                        // console.log('editar')
                                        // <Redirect to="/" />
                                        history.push(`/painel/clientes/alterar/${registro.id}`)
                                    }
                                    clicouEnviarEmail={() => {
                                        history.push(`/painel/clientes/enviar-email/${registro.id}`)
                                    }}
                                    clicouInativar={() => {
                                        registro.ativo = 'N';
                                        alterarConsulta([...consulta]);
                                    }}
                                    clicouAtivar={() => {

                                        registro.ativo = 'S';
                                        alterarConsulta([...consulta]);
                                    }}
                                    clicouEnviarSms={() => {
                                        history.push(`/painel/clientes/enviar-sms/${registro.id}`)
                                    }}
                                    clicouEnviarWhatsapp={() => {
                                        history.push(`/painel/clientes/enviar-whatsapp/${registro.id}`)
                                    }}
                                />
                            )}
                        </tbody>
                    </table>
                    <PaginacaoComponente
                        pagina={parametros.pagina}
                        registrosPorPagina={parametros.registrosPorPagina}
                        totalRegistros={totalRegistros}
                        alterouPagina={(pagina) => {
                            alterarParametros({...parametros, pagina: pagina});
                        }}
                    />
                    {carregando && 
                        <Carregador className="rounded-bottom" />
                    }
                </Card.Body>
                
            </Card>
        </Col>
    </Row>
}